html {
  background: white;
  font-family: "Andika", "Roboto", "Helvetica", sans-serif;
  margin: 0;
  padding: 0;
  overflow: hidden;
  user-select: none;
}

body {
  background: url("./images/selfie-snap__pattern.png");
  background-color: purple;
  width: 100vw;
  height: 100vh;
  background-position: center;
  background-repeat: repeat;
  font-family: "Andika", "Roboto", "Helvetica", sans-serif;
  margin: 0;
  padding: 0;
  overflow: auto;
  user-select: none;
}

a {
  color: white;
  text-decoration: none;
}

header > h1 {
  color: white;
  text-align: center;
}

.display-selfie {
  top: 30%;
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
}

.selfie__mask {
  mask-image: url("./images/selfie-snap__clip-mask.svg");
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  width: 300px;
  height: 400px;
  -webkit-mask-position-x: center;
  position: relative;
  margin: -50px auto -128px auto;
  background: #cccccc;
}

.mouth__overlay {
  background: url("./images/selfie-snap__mouthguide.svg");
  z-index: 5;
  height: 400px;
  width: 300px;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  left: calc(100% - 88%);

  /* top: -100px; */
}

.mouth__overlay {
  background: url("./images/selfie-snap__mouthguide.svg");
  z-index: 5;
  height: 400px;
  width: 300px;
  background-position: center;
  background-repeat: no-repeat;
  margin: auto;
  margin-bottom: -307px;
  margin-top: -94px;
}

.selfie__title {
  color: white;
  font-size: 1.7em;
  text-align: center;
  margin: auto;
  width: 60%;
  line-height: 1.5em;
  position: absolute;
  top: 5%;
  left: 0;
  right: 0;
}

.tuning-sliders__container {
  width: 360px;
  position: absolute;
  top: 69%;
  left: 0;
  right: 0;
  margin: auto;
}

.tuning-sliders__element {
  text-align: center;
  text-transform: uppercase;
  font-family: andika;
  padding-top: 10px;
  margin: auto;
  color: white;
}

header > h1 {
  color: #ffffff;
  text-align: center;
  margin-top: 52px;
}

h1 {
  color: #ffffff;
}

canvas {
  margin: auto;
  left: 10%;
  top: 30%;
  background-color: rgba(0, 0, 0, 0.3);
  position: relative;
  width: 150px;
  height: 200px;
  transform: translate(-30px, 0) !important;
}

#selfie-editor {
  border: 2px solid #ffffff;
  width: 300px;
  height: 400px;
  margin: auto;
}

#face-line {
  color: blue;
  font-size: 50px;
  position: relative;
  top: 255px;
  z-index: 1000;
  border-bottom: 5px solid blue;
  width: 106px;
  margin: auto;
}

/* @font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: url("./fonts/MaterialIcons-Regular.eot?") format("eot"), url("./fonts/MaterialIcons-Regular.woff2") format("woff2"), url("./fonts/MaterialIcons-Regular.woff") format("woff"), url("./fonts/MaterialIcons-Regular.ttf") format("truetype"), url("./fonts/MaterialIcons-Regular.svg#MaterialIcons") format("svg");
}

@font-face {
  font-family: "Andika";
  src: local('Andika-R'), url('./fonts/Andika-R.ttf') format("truetype");
} */

.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;

  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  bottom: 0;
}
.material-icons.tuning-sliders {
  font-size: 1em;
}

.login__container {
  display: flex;
  flex-direction: column;
  width: 50%;
  min-width: 300px;
  max-width: 400px;
  margin: auto;
  text-align: center;
  justify-content: center;
}

@media screen and (max-width: 800px) {
  .login__container {
    width: 100%;
  }
}

.login__icon {
  background-image: url("./images/selfie-snap__title-image.png");
  height: 300px;
  background-size: contain;
  background-repeat: no-repeat;
  margin: 0 auto 0;
}

.login__icon.temp {
  width: 50%;
}

.login__title {
  width: 100%;
  max-width: 300px;
  font-size: 1.5em;
  font-family: andika;
  color: white;
  text-align: center;
  margin: 0 auto 25px;
}

input {
  width: 100%;
  max-width: 300px;
  height: 45px;
  line-height: 28px;
  font-size: 1.5em;
  border-radius: 15px;
  text-align: center;
  font-family: andika;
  float: left;
  border: 0;
  color: rgba(0, 0, 0, 0.54);
  margin: 5px auto;
}

input[type="submit"],
button {
  background-color: #00bcd4;
  color: white;
  -moz-transition: background-color 500ms;
  -webkit-transition: background-color 500ms;
  transition: background-color 500ms;
  border-radius: 5px;
}

button {
  background-color: #00bcd4;
  color: #ffffff;
  border: 0;
  font-size: 1em;
  border-radius: 5px;
  width: 20%;
  height: 60px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.54);
}

.flex__buttons > button {
  padding: 0;
}

input[type="submit"]:hover,
.flex__buttons > button:hover {
  cursor: pointer;
  background-color: #0299ad;
}

.forgot-password.hover {
  cursor: pointer;
}

.input__selection__container {
  display: flex;
  flex-direction: row;
  text-align: center;
}

.input__selection {
  width: 50%;
  height: 100%;
  color: white;
  margin: 10% 5% 0;
  padding: 5%;
  border-radius: 25px;
  font-family: andika;
  background-color: rgba(255, 255, 255, 0.3);
}
.input__selection:hover,
.input__selection :active {
  cursor: pointer;
  background-color: #00bcd4;
}

.input__title {
  font-size: 1.5em;
}

.material-icons.input__icon__button {
  font-size: 3em;
  text-align: center;
  margin: auto;
}

.student__list {
  background: #80008080;
  width: 50%;
  min-width: 300px;
  max-width: 400px;
  margin: auto;
  overflow: auto;
}

.student {
  border-bottom: 1px solid #ffffff;
  margin-left: -1.5em;
  margin-right: 1em;
  height: 110px;
  margin-top: 13px;
}

.center__content {
  margin: auto;
}

input[type="submit"] {
  background-color: #00bcd4;
  color: #ffffff;
  width: 278px;
  border-radius: 5px;
}

.image__left {
  float: left;
}

video {
  margin: 10% auto;
  height: 200px;
}

@media screen and (max-width: 800px) {
  video {
    width: 300px;
  }
}

.user-name__left {
  float: left;
  line-height: 100px;
  margin-left: 24px;
}

.flex__buttons {
  width: 100%;
  display: flex;
  text-align: center;
  justify-content: space-evenly;
}

a.nav__button {
  background-color: #828282;
  border-radius: 5px;
  width: 20%;
  height: 60px;
  line-height: 60px;
  -moz-transition: background-color 2s;
  -webkit-transition: background-color 2s;
  transition: background-color 500ms;
  float: left;
  display: block;
}

a.nav__button:hover {
  background-color: #757575;
}

form {
  z-index: 1;
  text-align: center;
  margin: auto;
}

input[type="range"] {
  width: 250px;
}

.form__label {
  color: white;
}

input[type="submit"] {
  background-color: #00bcd4;
  padding: 12px 25px;
  border-radius: 5px;
  width: 166px;
  font-family: andika;
  font-size: 1em;
  border: 0;
}

.selfie__instructions {
  color: white;
  font-size: 13px;
  margin: 0 0 40px;
}

.selfie__zoomInstructions {
  margin: 100px auto 0;
  color: white;
}

.successPopup {
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  z-index: 100;
}

.successPopup__inner {
  position: relative;
  background-color: white;
  padding: 50px;
  font-size: 20px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  border-radius: 10px;
}

.successPopup__inner p {
  margin: 0 auto 2rem;
}

@media screen and (max-width: 800px) {
  .successPopup__inner {
    width: 90%;
  }
}

.loader {
  margin: 75px;
  height: 100px;
  width: 100px;
  position: relative;
  animation: rotating 1s ease-in-out infinite;
}

.loader__wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  padding: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
}

.loader:before {
  left: 0;
  box-shadow: 60px 60px 0;
  content: "";
  display: block;
  width: 40px;
  height: 40px;
  position: absolute;
  animation: centering 2s ease-in-out infinite;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.75);
  color: rgba(255, 255, 255, 0.75);
  top: 0;
}

.loader:after {
  left: calc(100% - 40px);
  box-shadow: -60px 60px 0;
  content: "";
  display: block;
  width: 40px;
  height: 40px;
  position: absolute;
  animation: centering 2s ease-in-out infinite;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.75);
  color: rgba(255, 255, 255, 0.75);
  top: 0;
}

@keyframes centering {
  50% {
    top: calc(50% - (40px / 2));
    left: calc(50% - (40px / 2));
    box-shadow: 0 0 0 rgba(255, 255, 255, 0.75);
    background-color: rgba(255, 255, 255, 0.75);
  }
}

@keyframes rotating {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
